import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from "../components/layout"
import ArticleItem from '../components/articleItem';


const Articles = props => {
  return (
    <Layout>
      <div className="listing">
        {
          props.data.allWordpressPost.edges.map((item, index) => {
            return (
              <ArticleItem
                key={item.node.id}
                slug={item.node.slug}
                title={item.node.title}
                fluid={item.node.featured_media.localFile ? item.node.featured_media.localFile.childImageSharp.fluid : ''}
                content={item.node.excerpt}
                format={item.node.format}
                categories={item.node.categories}
              />
            )
          })
        }
      </div>
    </Layout>
  )
}

export default Articles;

export const query = graphql`
  query articlesQuery {
    allWordpressPost(filter: { format: { eq: "standard" } }) {
      edges {
        node {
          id
          slug
          status
          title
          excerpt
          template
          format
          content
          tags {
            id
            name
            slug
          }
          categories {
            id
            name
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
